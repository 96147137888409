var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{},[_c('div',[_c('v-alert',{staticClass:"alert",class:{'alert-error':_vm.alertType=='error', 'alert-success':_vm.alertType=='success'},attrs:{"border":"left","dark":"","dismissible":""},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")])],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10 ml-auto mr-auto"},[_c('v-container',[_c('div',{staticClass:"mb-4 mt-8 d-flex"},[_c('div',{staticClass:"col-md-11"},[_c('h1',[_vm._v("Pending Deductions")])])]),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"loading":_vm.isLoading,"loading-text":"Fetching records... Please wait","headers":_vm.headers,"items":_vm.deductionList,"search":_vm.search},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getEmployeeName(item.employee))+" ")]}},{key:"item.effectiveDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.effectiveDate))+" ")]}},{key:"item.dedecutionAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatMoney")(item.dedecutionAmount))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('Button',{staticClass:"mr-2",attrs:{"btnType":'Submit',"label":'Approve'},on:{"onClick":function($event){return _vm.openApprove(item)}}}),_c('Button',{staticClass:"mr-2",attrs:{"btnType":'Cancel',"label":'Reject'},on:{"onClick":function($event){return _vm.openRejectModal(item)}}})],1)]}}])})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.rejectDialog),callback:function ($$v) {_vm.rejectDialog=$$v},expression:"rejectDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline text-center"},[_c('h3',[_vm._v("Reason for rejecting")])]),_c('v-divider',{staticClass:"mx-4 mb-5"}),_c('v-card-text',{staticClass:"mt-5"},[_c('v-form',{ref:"commentForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('TextField',{attrs:{"label":'Comment'},model:{value:(_vm.comments),callback:function ($$v) {_vm.comments=$$v},expression:"comments"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('Button',{staticClass:"mr-4",attrs:{"label":'Reject',"btnType":'Submit',"isLoading":_vm.isProcessing,"disabled":!_vm.valid},on:{"onClick":_vm.reject}}),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.closeRejectModal}},[_vm._v("Close")])],1)],1)],1),_c('ConfirmationDialog',{attrs:{"title":'Are you sure you want to submit?',"isLoading":_vm.isProcessing,"dialog":_vm.dialog,"btnTitle":'Yes'},on:{"close":_vm.closeApprove,"btnAction":_vm.approve}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }